(function ($, Drupal, document, generic) {
  Drupal.behaviors.customCollectionProductAddV1 = {
    collectionId: false,

    attach: function (context) {
      this.context = context;

      this.setEvents();
      this.setElements();
      this.setData();
    },

    setElements: function () {
      this.$maxErrorBanner = $('.js-max-error-banner', this.context);
    },

    setData: function () {
      this.maxErrorProductText = $('.js-custom-collection-landing-v1', this.context).data('custom-collection-limit-error-msg-product');
    },

    setEvents: function () {
      var self = this;

      $(document).on('product.init', function () {
        self.addToGift();
      });

      $(document).on('customCollection:init', function () {
        self.addToGift();
      });
    },

    addToGift: function (args) {
      args = args || {};

      var self = this;
      var $addBtns = args.$btns || $('.js-custom-collection-product-add-v1', this.context);
      let maxAllowedSku = 0;
      var customCollectionLanding = Drupal.behaviors.customCollectionLandingV1;
      if (customCollectionLanding && customCollectionLanding.loadMoreData.maxAllowedSku) {
        maxAllowedSku = parseInt(customCollectionLanding.loadMoreData.maxAllowedSku);
      }

      $addBtns.on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();

        self.$addToBagButton = $(this);
        let collectionTotalItem = 0;
        var customCollectionProductTray = Drupal.behaviors.customCollectionProductTrayV1;
        if (customCollectionProductTray && customCollectionProductTray.collection.total_items) {
          collectionTotalItem = parseInt(customCollectionProductTray.collection.total_items);
        }

        if (maxAllowedSku > 0 && collectionTotalItem >= maxAllowedSku) {
          self.showMaxError(self.$addToBagButton);
          return false;
        }

        var skuId = self.$addToBagButton.data('sku-base-id');
        var $loadingElement = self.$addToBagButton.parent().find('.js-btn-loading');

        if (self.collectionId) {
          self.triggerAddToGift({
            skuId: skuId,
            collectionId: self.collectionId,
            $btn: self.$addToBagButton,
            $loading: $loadingElement
          });
        } else {
          $(document).trigger('customCollection:getCollectionId', {
            callback: function (collectionId) {
              self.collectionId = collectionId;
              self.triggerAddToGift({
                skuId: skuId,
                collectionId: self.collectionId,
                $btn: self.$addToBagButton,
                $loading: $loadingElement
              });
            }
          });
        }
      });

      $(document).on('customCollection:addToGift:success', function () {
        self.$addToBagButton.removeClass('button--disabled');
      });

      $(document).on('customCollection:addToGift:failure', function (event, errorRpcResponse) {
        self.$addToBagButton.removeClass('button--disabled');

        self.showErrors(errorRpcResponse, self.$addToBagButton);
      });
    },

    triggerAddToGift: function (args) {
      var $btn = args.$btn;

      if (!$btn.hasClass('button--disabled')) {
        $(document).trigger('customCollection:addToGift', {
          skuId: args.skuId,
          collectionId: args.collectionId
        });
      }

      $btn.addClass('button--disabled');
    },

    // Method to handle the error display after a RPC call.If error message is incorrect, use the RBE2 to set the error message text key returned.
    showErrors: function (errorResponse, $btn) {
      var errorObjectsArray = errorResponse.getMessages();
      var errorMsg = '';
      var i = 0;

      for (i = 0; i < errorObjectsArray.length; i++) {
        errorMsg = errorObjectsArray[i].key;
        if (errorMsg === 'collection.max_skus.over' || errorMsg === 'collection.max_sku_qty.over') {
          this.showMaxError($btn);

          return;
        }
      }
      generic.showErrors(errorObjectsArray);
    },

    showMaxError: function ($addBtn) {
      var productError = $addBtn.parent().next('.js-max-error-message');
      var productErrorQuickShop = $addBtn.prev('.js-max-error-message--quickshop');

      productError.html(this.maxErrorProductText);

      if (this.$maxErrorBanner.hasClass('hidden')) {
        this.$maxErrorBanner.removeClass('hidden');
      }

      if (productError.hasClass('hidden')) {
        productError.removeClass('hidden');
      }

      if (productErrorQuickShop.hasClass('hidden')) {
        productErrorQuickShop.removeClass('hidden');
      }
    }
  };
})(jQuery, Drupal, document, window.generic || {});
